import React, { useEffect, useState } from 'react'
import Productsc from '../components/Products'
import Subscribe from '../components/Subscribe'
import ProjectsComponent from '../components/ProjectsComponent'
import { useTranslation } from "react-i18next";
import "../utils/i18n";
import axios from 'axios';
import Loading from '../components/Loading';

const Products = () => {

  const [languages, setLanguages] = useState([]);
  const { t, i18n } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchLanguages = async () => {
      try {
        const response = await axios.get("http://localhost:8000/api"); 
        setLanguages(response.data.languages); 
      } catch (error) {
        console.error("Error fetching languages:", error);
      }
    };

    fetchLanguages();
    setIsLoading(false);
  }, []);

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="mx-auto container ">
      <h1 className="text-kintecs font-oswald font-bold text-5xl mt-12 pl-4 md:pl-0">{t("Products")}</h1>
      <Productsc />

      <h2 className="text-kintecs font-oswald font-bold text-4xl mt-24 pr-4 pl-4 md:pr-0 md:pl-0">{t("proj")}</h2>

      <ProjectsComponent />

      <Subscribe />

    </div>
  )
}

export default Products