import React, { useState, useEffect } from 'react';
import axiosInstance from '../utils/axiosInstance';
import { IoMdLock } from 'react-icons/io';

const Settings = () => {
  const [languages, setLanguages] = useState([]);
  const [newLanguage, setNewLanguage] = useState({ code: '', name: '' });
  const [translations, setTranslations] = useState({});
  const [selectedLang, setSelectedLang] = useState(null);
  const [error, setError] = useState('');

  useEffect(() => {
    fetchLanguages();
  }, []);

  const fetchLanguages = async () => {
    try {
      const response = await axiosInstance.get('/api/langapi/langs');
      const langs = response.data.langs || [];
      const englishLang = langs.find(l => l.code === 'en');
      const otherLangs = langs.filter(l => l.code !== 'en');
      
      if (!englishLang) {
        setLanguages([
          { code: 'en', name: 'English', isLocked: true },
          ...otherLangs
        ]);
      } else {
        setLanguages([
          { ...englishLang, isLocked: true },
          ...otherLangs
        ]);
      }
    } catch (error) {
      console.error('Error fetching languages:', error);
      setError('Diller yüklenirken bir hata oluştu');
    }
  };

  const handleAddLanguage = async (e) => {
    e.preventDefault();
    if (newLanguage.code === 'en') {
      setError('İngilizce dili eklenemez');
      return;
    }
    try {
      await axiosInstance.post('/api/langapi/langs', newLanguage);
      setNewLanguage({ code: '', name: '' });
      fetchLanguages();
    } catch (error) {
      console.error('Error adding language:', error);
      setError('Dil eklenirken bir hata oluştu');
    }
  };

  const handleDeleteLanguage = async (code) => {
    if (code === 'en') {
      setError('İngilizce dili silinemez');
      return;
    }
    try {
      await axiosInstance.delete(`/api/langapi/langs/${code}`);
      fetchLanguages();
    } catch (error) {
      console.error('Error deleting language:', error);
      setError('Dil silinirken bir hata oluştu');
    }
  };

  const handleEditTranslations = async (lang) => {
    try {
      console.log('Fetching translations for:', lang.code);
      
      const enResponse = await axiosInstance.get('/api/en');
      console.log('EN Response:', enResponse.data);

      const langResponse = await axiosInstance.get(`/api/${lang.code}`);
      console.log('Selected Language Response:', langResponse.data);

      if (enResponse.data && enResponse.data.translations) {
        const translations = {
          en: enResponse.data.translations,
          current: langResponse.data?.translations || {}
        };
        
        console.log('Setting translations:', translations);
        setTranslations(translations);
        setSelectedLang(lang);
      }
    } catch (error) {
      console.error('Translation fetch error:', error);
      setError('Çeviriler yüklenirken bir hata oluştu');
    }
  };

  const handleSaveTranslations = async () => {
    try {
      console.log('Saving translations for:', selectedLang.code);
      console.log('Translations to save:', translations.current);
      
      await axiosInstance.post(`/api/langs/${selectedLang.code}`, {
        translations: translations.current
      });

      setSelectedLang(null);
      setTranslations({});
      setError('');
    } catch (error) {
      console.error('Translation save error:', error);
      setError('Çeviriler kaydedilirken bir hata oluştu');
    }
  };

  return (
    <div className="p-8 bg-gray-100 min-h-screen ml-[218px]">
      <div className="max-w-4xl mx-auto">
        <h1 className="text-2xl font-bold mb-8">Language Settings</h1>

        {error && (
          <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4">
            {error}
          </div>
        )}

        {!selectedLang ? (
          <>
            <div className="bg-white rounded-lg shadow-sm p-6 mb-8">
              <h2 className="text-xl font-semibold mb-4">Add New Language</h2>
              <form onSubmit={handleAddLanguage} className="flex flex-col space-y-4">
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <label className="block text-sm text-gray-700 mb-1">Language Code</label>
                    <input
                      type="text"
                      value={newLanguage.code}
                      onChange={(e) => {
                        const code = e.target.value.toLowerCase();
                        if (code === 'en') {
                          setError('İngilizce dili eklenemez');
                          return;
                        }
                        setNewLanguage({ ...newLanguage, code });
                      }}
                      className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500"
                      required
                      maxLength="2"
                      pattern="[a-z]{2}"
                    />
                  </div>
                  <div>
                    <label className="block text-sm text-gray-700 mb-1">Language Name</label>
                    <input
                      type="text"
                      value={newLanguage.name}
                      onChange={(e) => setNewLanguage({ ...newLanguage, name: e.target.value })}
                      className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500"
                      required
                    />
                  </div>
                </div>
                <button
                  type="submit"
                  className="w-full bg-blue-500 text-white py-2 rounded-md hover:bg-blue-600 transition-colors"
                >
                  Add Language
                </button>
              </form>
            </div>

            <div className="bg-white rounded-lg shadow-sm p-6">
              <h2 className="text-xl font-semibold mb-4">Language List</h2>
              <table className="w-full">
                <thead>
                  <tr className="text-left border-b">
                    <th className="pb-2 text-gray-600 font-medium">CODE</th>
                    <th className="pb-2 text-gray-600 font-medium">NAME</th>
                    <th className="pb-2 text-gray-600 font-medium text-right">ACTIONS</th>
                  </tr>
                </thead>
                <tbody>
                  {languages.map((lang) => (
                    <tr key={lang.code} className="border-b last:border-0">
                      <td className="py-3">{lang.code}</td>
                      <td className="py-3">
                        {lang.name}
                        {lang.isLocked && (
                          <IoMdLock className="inline-block ml-2 text-gray-500" size={16} />
                        )}
                      </td>
                      <td className="py-3 text-right">
                        <button
                          onClick={() => handleEditTranslations(lang)}
                          className="text-blue-500 hover:text-blue-700 mr-4"
                        >
                          Edit Translations
                        </button>
                        {!lang.isLocked && (
                          <button
                            onClick={() => handleDeleteLanguage(lang.code)}
                            className="text-red-500 hover:text-red-700"
                          >
                            Delete
                          </button>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>
        ) : (
          <div className="bg-white rounded-lg shadow-sm p-6">
            <div className="border-b pb-4 mb-4">
              <div className="flex justify-between items-center">
                <h2 className="text-xl font-semibold">
                  Editing Translations for {selectedLang.name}
                </h2>
                <div className="flex space-x-2">
                  <button
                    onClick={handleSaveTranslations}
                    className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
                  >
                    Save
                  </button>
                  <button
                    onClick={() => {
                      setSelectedLang(null);
                      setTranslations({});
                    }}
                    className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>

            <div className="space-y-4">
              {Object.entries(translations.en || {}).map(([key, enValue]) => (
                <div key={key} className="border rounded p-4 bg-gray-50">
                  <div className="text-sm font-medium mb-2 text-gray-700">{enValue}</div>
                  <input
                    type="text"
                    value={translations.current[key] || ''}
                    placeholder={`${selectedLang.name} translation...`}
                    onChange={(e) =>
                      setTranslations({
                        ...translations,
                        current: {
                          ...translations.current,
                          [key]: e.target.value
                        }
                      })
                    }
                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500"
                  />
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Settings;