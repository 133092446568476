import React, { useEffect, useState } from 'react'
import Subscribe from '../components/Subscribe'
import { useTranslation } from "react-i18next";
import "../utils/i18n";
import axios from 'axios';

const About = () => {

  const [languages, setLanguages] = useState([]);
  const { t, i18n } = useTranslation();
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchLanguages = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`${backendUrl}/api`);
        setLanguages(response.data.languages);
      } catch (error) {
        console.error("Error fetching languages:", error);
      } finally {
        setIsLoading(false);
      }
    };
  
    fetchLanguages();
  }, []);

  if (isLoading) {
    return <p>Loading...</p>;
  }

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
  };


  return (
    <div className="">
         <h1 className="text-kintecs font-oswald font-bold text-5xl mt-12 mx-auto container  pr-4 pl-4 md:pr-0 md:pl-0">{t("About Us")}</h1>

         <div className=" bg-kintecs w-full">

            <div className="mx-auto container text-white pt-24 pb-24 mt-12  pr-4 pl-4 md:pr-0 md:pl-0">

                <h1 className="font-oswald text-4xl font-bold">{t("who")}</h1>

                <p className="mt-4">{t("who2")}</p>

                <p className="mt-4">{t("who3")}</p>
           
                <p className="mt-4">{t("who4")}</p>
            
                <h2 className="mt-4 font-oswald text-2xl font-bold">{t("who5")}</h2>
            </div>
         </div>

         <div className="mx-auto container grid grid-cols-1 lg:grid-cols-3 gap-x-16 pb-24 mt-24 items-center  pr-4 pl-4 md:pr-0 md:pl-0">
  <div className="lg:col-span-1 mb-8 lg:mb-0">
    <img className="rounded-lg w-full" src="assets/leaders.webp" alt="Leader" />
  </div>

  <div className="lg:col-span-2">
    <h1 className="font-bold text-kintecs text-4xl">{t("led")}</h1>
    <p className="mt-6">
    {t("led2")}
    </p>
    <p className="mt-6">
    {t("led3")}
    </p>
    <p className="mt-6 font-bold">{t("name")}</p>
    <p>{t("ceo")}</p>
  </div>
</div>

<div className="mx-auto container grid grid-cols-1 lg:grid-cols-3 gap-x-16 pb-24 mt-24 items-center pr-4 pl-4 md:pr-0 md:pl-0">
  
  <div className="lg:col-span-2 mb-8 lg:mb-0">
    <h1 className="font-bold text-kintecs text-4xl">{t("vision")}</h1>
    <p className="mt-6">
    {t("vision2")}
    </p>
  </div>

  <div className="lg:col-span-1">
    <img className="rounded-lg w-full" src="assets/vision.webp" alt="Our Vision" />
  </div>
  
</div>

<div className="mx-auto container grid grid-cols-1 lg:grid-cols-3 gap-x-16 pb-24 mt-24 items-center pr-4 pl-4 md:pr-0 md:pl-0">
  
  <div className="lg:col-span-1 mb-8 lg:mb-0">
    <img className="rounded-lg w-full" src="assets/mision.webp" alt="Our Mission" />
  </div>

  <div className="lg:col-span-2">
    <h1 className="font-bold text-kintecs text-4xl">{t("mision")}</h1>
    <p className="mt-6">
    {t("mision2")}
    </p>
  </div>

</div>

<div className="mx-auto container grid grid-cols-1 lg:grid-cols-3 gap-x-16 pb-24 mt-24 items-center pr-4 pl-4 md:pr-0 md:pl-0">
  
  <div className="lg:col-span-2">
    <h1 className="font-bold text-kintecs text-4xl">{t("Policy")}</h1>
    <p className="mt-6">
    {t("Policy2")}
    </p>
  </div>

  <div className="lg:col-span-1 mb-8 lg:mb-0">
    <img className="rounded-lg w-full" src="assets/policy.webp" alt="Policy" />
  </div>

</div>

            <Subscribe />

    </div>
  )
}

export default About