import React, { useEffect, useState } from 'react'
import { useTranslation } from "react-i18next";
import "../utils/i18n";
import axios from 'axios';

const Subscribe = () => {

  const [languages, setLanguages] = useState([]);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const fetchLanguages = async () => {
      try {
        const response = await axios.get("http://localhost:8000/api"); 
        setLanguages(response.data.languages); 
      } catch (error) {
        console.error("Error fetching languages:", error);
      }
    };

    fetchLanguages();
  }, []);

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
  };

  return (
    <div>
        <div className="flex justify-center mt-48 mb-24 p-4 md:p-0">
        <div className="max-w-[460px]">
            <h1 className="font-oswald text-xl font-bold text-kintecs">{t("subscribe")}</h1>
            <input type="email" placeholder={t("mail")} class="mt-4 w-full px-3 py-2 border border-kintecs rounded-md focus:outline-none focus:ring-2 focus:ring-gray-600" />
         
         <div class="flex items-center space-x-4 mb-4 mt-4">
            <label class="flex items-center space-x-2">
            <input type="checkbox" class="form-checkbox h-5 w-5 bg-gray-200 border-gray-300 rounded   checked:text-white  accent-kintecs" />
            <span class="text-gray-700 text-sm">{t("news")}</span>
            </label>
            <label class="flex items-center space-x-2">
            <input type="checkbox" class="form-checkbox h-5 w-5 bg-gray-200 border-gray-300 rounded   checked:text-white  accent-kintecs" />
            <span class="text-gray-700 text-sm">{t("blog")}</span>
            </label>
         </div>

         <div class="mb-4 ">
            <label class="flex items-start space-x-2">
            <input type="checkbox" class="form-checkbox h-5 w-5 bg-gray-200 border-gray-300 rounded   checked:text-white  accent-kintecs" />
            <span class="text-gray-700 text-sm">
                <a href="#" class="underline text-kintecs">{t("agree")}</a>
            </span>
            </label>
         </div>

         <button class="w-full p-3 bg-kintecs text-white font-bold uppercase hover:bg-gray-700 focus:outline-none cursor-pointer">{t("sub")}</button>

        </div>

    </div>
    </div>
  )
}

export default Subscribe