import React, { useState, useEffect } from 'react';
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';
import { Autoplay } from 'swiper/modules';
import { useParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import "../utils/i18n";
import axiosInstance from "../utils/axiosInstance";

const Project_Details = () => {
    const backendUrl = "https://api.345.com.tr";
    const [isExpanded, setIsExpanded] = useState(false);
    const { slug } = useParams();
    const { t, i18n } = useTranslation();
    const [projectDetail, setProjectDetail] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const currentLanguage = i18n.language;
    const [otherProjects, setOtherProjects] = useState([]);

    useEffect(() => {
        const fetchProjectDetails = async () => {
            setLoading(true);
            try {
                console.log('Fetching project details for slug:', slug);
                const response = await axiosInstance.get(`/api/projects/slug/${slug}`);
                console.log('Project details response:', response.data);
                if (response.data && response.data.project) {
                    setProjectDetail(response.data.project);
                }
            } catch (error) {
                console.error("Error fetching project details:", error);
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchProjectDetails();
    }, [slug]);

    useEffect(() => {
        const fetchOtherProjects = async () => {
            try {
                const response = await axiosInstance.get("/api/projects");
                if (response.data && response.data.projects) {
                    // Mevcut projeyi hariç tut ve rastgele 4 proje seç
                    const filteredProjects = response.data.projects
                        .filter(p => p.slug !== slug)
                        .sort(() => 0.5 - Math.random())
                        .slice(0, 4);
                    setOtherProjects(filteredProjects);
                }
            } catch (error) {
                console.error("Error fetching other projects:", error);
            }
        };

        fetchOtherProjects();
    }, [slug]);

    useEffect(() => {
        Fancybox.bind("[data-fancybox='gallery']", {
            infinite: false,
        });

        return () => {
            Fancybox.destroy();
        };
    }, [projectDetail]);

    if (loading) {
        return <div className="flex justify-center items-center min-h-screen">Loading...</div>;
    }

    if (error) {
        return <div className="flex justify-center items-center min-h-screen text-red-500">Error: {error}</div>;
    }

    if (!projectDetail) {
        return <div className="flex justify-center items-center min-h-screen">Project not found!</div>;
    }

    const localizedTitle = projectDetail.title?.[currentLanguage] || projectDetail.title?.en;
    const localizedProjectName = projectDetail.projectName?.[currentLanguage] || projectDetail.projectName?.en;
    const localizedDescription = projectDetail.descb?.[currentLanguage] || projectDetail.descb?.en;

    const localizedOtherProjects = otherProjects.map(project => ({
        ...project,
        projectName: project.projectName?.[currentLanguage] || project.projectName?.en,
        title: project.title?.[currentLanguage] || project.title?.en,
    }));

    return (
        <div className="mx-auto container">
            <div className="relative">
                <img
                    className="w-full h-[200px] md:h-[400px] object-cover object-bottom"
                    src={`${backendUrl}${projectDetail.banner[0]}`}
                    alt={localizedProjectName}
                />
                <div className="absolute inset-0 bg-gradient-to-t from-kintecs to-transparent opacity-80"></div>
                <div className="absolute bottom-4 left-4 md:bottom-6 md:left-6">
                    <h1 className="text-3xl md:text-6xl text-white font-bold font-oswald">
                        {localizedProjectName}
                    </h1>
                </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-4 gap-8 mt-2 md:mt-12 mb-6 md:mb-12">
                <div className="">
                    <div className="bg-white p-6 md:shadow-2xl rounded-lg md:border border-gray-500 border-opacity-30 text-kintecs text-sm">
                        <h2 className="font-bold text-base">{t("Project Name")}</h2>
                        <p>{localizedProjectName}</p>
                        <h2 className="font-bold mt-2 text-base">{t("Location")}</h2>
                        <p>{projectDetail.location}</p>
                        <h2 className="font-bold text-base mt-2">{t("Product")}</h2>
                        <p>{projectDetail.product}</p>
                        <h2 className="font-bold text-base mt-2">{t("Size")}</h2>
                        <p>{projectDetail.size}</p>
                        <h2 className="font-bold text-base mt-2">{t("Glazing")}</h2>
                        <p>{projectDetail.glazing}</p>
                        <h2 className="font-bold text-base mt-2">{t("Project Number")}</h2>
                        <p>{projectDetail.projectNumber}</p>
                    </div>
                </div>

                <div className="col-span-3 pr-4 pl-4 md:pr-0 md:pl-0">
                    <div className={`text-base ${isExpanded ? '' : 'line-clamp-6'}`}>
                        {localizedDescription}
                    </div>
                    {localizedDescription && localizedDescription.length > 200 && (
                        <button
                            onClick={() => setIsExpanded(!isExpanded)}
                            className="text-blue-600 hover:text-blue-800 mt-2"
                        >
                            {isExpanded ? t('Read Less') : t('Read More')}
                        </button>
                    )}
                </div>
            </div>

            <div className="grid grid-cols-2 md:grid-cols-3 gap-4 pr-4 pl-4 md:pr-0 md:pl-0 mt-12 md:mt-0">
                {projectDetail.pics.map((pic, index) => (
                    <a key={index} href={`${backendUrl}${pic}`} data-fancybox="gallery">
                        <img
                            className="w-full md:h-[340px] object-cover rounded transition-transform duration-500 ease-in-out transform hover:scale-105 hover:shadow-lg"
                            src={`${backendUrl}${pic}`}
                            alt={`${localizedProjectName} - Image ${index + 1}`}
                        />
                    </a>
                ))}
            </div>

            <div className="flex justify-between mt-24">
                <h1 className="font-oswald font-bold text-kintecs md:text-4xl text-3xl">{t("Other Projects")}</h1>
                <Link to="/projects">
                    <button className="text-white bg-kintecs rounded-lg py-3 px-4 md:text-base text-xs font-oswald font-bold">
                        {t("View More")}
                    </button>
                </Link>
            </div>
            <div className="w-full border-b border-kintecs mt-3"></div>

            <div className="mt-10 mb-24">
                <Swiper
                    breakpoints={{
                        140: { slidesPerView: 1, spaceBetween: -40 },
                        768: { slidesPerView: 2, spaceBetween: 20 },
                        1024: { slidesPerView: 3, spaceBetween: 30 },
                        1280: { slidesPerView: 4, spaceBetween: 30 }
                    }}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}
                    speed={5000}
                    modules={[Autoplay, Pagination]}
                    className="mySwiper"
                >
                    {localizedOtherProjects.map((project, index) => (
                        <SwiperSlide key={index}>
                            <Link to={`/projects/${project.slug}`}>
                                <div className="relative group">
                                    <img
                                        className="w-[300px] h-[200px] object-cover mt-3 rounded transform transition-transform duration-500 group-hover:scale-110"
                                        src={`${backendUrl}${project.banner[0]}`}
                                        alt={project.projectName}
                                    />
                                </div>
                                <h2 className="font-oswald text-xl font-bold mt-3">{project.projectName}</h2>
                                <h3>{project.location}</h3>
                            </Link>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </div>
    );
};

export default Project_Details;