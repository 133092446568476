import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import "../utils/i18n";
import axios from 'axios';
import Country from '../components/Country';
import Loading from '../components/Loading';

const Kufc = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: ''
  });
  const { t } = useTranslation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const response = await axios.post('https://api.345.com.tr/api/forms/new', formData);
      
      if (response.data.success) {
        alert(t('form_success'));
        setFormData({
          name: '',
          email: '',
          phone: '',
          message: ''
        });
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      alert(t('form_error'));
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = (e) => {
    if (e && e.target) {
      setFormData(prev => ({
        ...prev,
        [e.target.name]: e.target.value
      }));
    }
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="w-full p-6 bg-white border rounded-t-xl shadow-lg">
      <h2 className="font-bold text-lg mb-4 text-kintecs">Contact Information</h2>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          name="name"
          value={formData.name}
          onChange={handleChange}
          className="w-full border rounded-lg p-3 mb-4"
          placeholder={t("Name & Surname")}
          required
        />
        <input
          type="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          className="w-full border rounded-lg p-3 mb-4"
          placeholder={t("Email Address")}
          required
        />
        <Country 
          onChange={handleChange}
          value={formData.phone}
        />
        <textarea
          name="message"
          value={formData.message}
          onChange={handleChange}
          className="w-full border rounded-lg p-3 mb-4"
          placeholder={t("Your Message")}
          rows="4"
          required
        ></textarea>
        <p className="text-sm text-gray-500 mb-4">{t("terms_text")}</p>
        <div className="flex items-start mb-4">
          <input 
            type="checkbox" 
            required
            className="mt-1 bg-gray-200 border-gray-300 rounded checked:text-white accent-kintecs" 
          />
          <label className="ml-2 text-sm text-gray-500">
            {t("terms_agree")}
          </label>
        </div>
        <button
          type="submit"
          disabled={isLoading}
          className={`w-full p-3 ${isLoading ? 'bg-gray-400' : 'bg-kintecs hover:bg-gray-500'} text-white font-bold rounded-lg`}
        >
          {isLoading ? t('sending') : t('Submit')}
        </button>
      </form>
    </div>
  );
};

export default Kufc;