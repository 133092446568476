import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { i18nInstance } from './utils/i18n';
import Header from './components/Header';
import Home from './pages/Home';
import Footer from './components/Footer';
import Products from './pages/Products';
import Projects from './pages/Projects';
import Videos from './pages/Videos';
import Catalogues from './pages/Catalogues';
import About from './pages/About';
import Contact from './pages/Contact';
import Request from './pages/Request';
import Kufc from './pages/Kufc';
import Kufb from './pages/Kufb';
import Project_Details from './pages/Project_Details';
import Catalogues_Details from './pages/Catalogues_Details';
import ScrollToTop from './components/ScrollToTop';
import Admin from './pages/Admin';
import AddProjects from './pages/AddProjects';
import { AdminHeader } from './components/AdminHeader';
import EditProject from './pages/EditProject';
import Settings from './pages/Settings';
import AdminVideo from './pages/AdminVideo';
import AddVideo from './pages/AddVideo';
import AdminForms from './pages/AdminForms';
import SliderManagement from './pages/admin/SliderManagement';
import PrivateRoute from './components/PrivateRoute';
import ProtectedRoute from './components/ProtectedRoute';

const App = () => {
  const [isI18nInitialized, setIsI18nInitialized] = useState(false);

  useEffect(() => {
    const initI18n = async () => {
      await i18nInstance;
      setIsI18nInitialized(true);
    };
    initI18n();
  }, []);

  if (!isI18nInitialized) {
    return <div>Loading...</div>;
  }

  return (
    <Router>
      <ScrollToTop /> 
      <Routes>
        <Route path="/" element={
          <>
        <Header /> 
        <Home />
        <Footer />
        </>} />  
        <Route path="/products" element={
          <>
          <Header />
          <Products />
          <Footer />
          </>
        } /> 
        <Route path="/projects" element={
          <>
          <Header />
          <Projects />
          <Footer />
          </>
          } /> 
        <Route path="/videos" element={
          <>
          <Header />
          <Videos />
          <Footer />
          </>
          } /> 
        <Route path="/admin" element={<Admin />} />
        <Route path="/admin/add-project" element={
          <ProtectedRoute>
            <AdminHeader />
            <AddProjects />
          </ProtectedRoute>
        } />
        <Route path="/admin/videos" element={
          <ProtectedRoute>
            <AdminHeader />
            <AdminVideo />
          </ProtectedRoute>
        } />
        <Route path="/admin/add-video" element={
          <ProtectedRoute>
            <AdminHeader />
            <AddVideo />
          </ProtectedRoute>
        } />
        <Route path="/admin/edit-project" element={
          <ProtectedRoute>
            <AdminHeader />
            <EditProject />
          </ProtectedRoute>
        } />
        <Route path="/admin/edit-project/:id" element={
          <ProtectedRoute>
            <AdminHeader />
            <EditProject />
          </ProtectedRoute>
        } />
        <Route path="/admin/settings" element={
          <ProtectedRoute>
            <AdminHeader />
            <Settings />
          </ProtectedRoute>
        } />
        <Route path="/catalogues" element={
          <>
          <Header />
          <Catalogues />
          <Footer />
          </>
          } /> 
        <Route path="/about-us" element={
          <>
          <Header />
          <About />
          <Footer />
          </>
          } /> 
        <Route path="/contact" element={
          <>
          <Header />
          <Contact />
          <Footer />
          </>
          } /> 
        <Route path="/request-form" element={
          <>
          <Header />
          <Request />
          <Footer />
          </>
          } /> 
        <Route path="/products/ku-fc" element={
          <>
          <Header />
          <Kufc />
          <Footer />
          </>
          } /> 
        <Route path="/products/ku-fb" element={
          <>
          <Header />
          <Kufb />
          <Footer />
          </>
          } /> 
        <Route path="/projects/:slug" element={
          <>
          <Header />
          <Project_Details />
          <Footer />
          </>
          } />
        <Route path="/catalogues/:slug" element={
          <>
          <Header />
          <Catalogues_Details />
          <Footer />
          </>
          } />
        <Route path="/admin/forms" element={
          <ProtectedRoute>
            <AdminForms />
          </ProtectedRoute>
        } />
        <Route path="/admin/slider" element={<PrivateRoute><SliderManagement /></PrivateRoute>} />
      </Routes>
      
    </Router>
  );
};

export default App;