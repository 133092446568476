import React, { useEffect, useState } from 'react'
import ProjectsComponent from '../components/ProjectsComponent'
import Subscribe from '../components/Subscribe'
import { useTranslation } from "react-i18next";
import "../utils/i18n";
import axiosInstance from '../utils/axiosInstance';

const Projects = () => {

  const [languages, setLanguages] = useState([]);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const fetchLanguages = async () => {
      try {
        const response = await axiosInstance.get("/api/langs");
        if (response.data && response.data.langs) {
          setLanguages(response.data.langs);
        }
      } catch (error) {
        console.error("Error fetching languages:", error);
      }
    };

    fetchLanguages();
  }, []);

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
  };


  return (
    <div className="mx-auto container">
        <h1 className="text-kintecs font-oswald font-bold text-5xl mt-12 pr-4 pl-4 md:pr-0 md:pl-0">{t("Projects")}</h1>
        <ProjectsComponent />
        <Subscribe />
    </div>
  )
}

export default Projects