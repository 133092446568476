import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import axiosInstance from "./axiosInstance";

const loadTranslations = async (lang) => {
    try {
        const response = await axiosInstance.get(`/api/${lang}`);
        if (response.data && response.data.translations) {
            return response.data.translations;
        }
        console.error("No translations found for language:", lang);
        return {};
    } catch (error) {
        console.error("Error loading translations:", error);
        return {};
    }
};

const initializeI18n = async () => {
    const initialLang = localStorage.getItem('i18nextLng') || 'en';
    const initialTranslations = await loadTranslations(initialLang);

    await i18n
        .use(initReactI18next)
        .init({
            lng: initialLang,
            fallbackLng: "en",
            debug: true,
            interpolation: {
                escapeValue: false,
            },
            resources: {
                [initialLang]: {
                    translation: initialTranslations
                },
            },
        });

    i18n.on("languageChanged", async (lang) => {
        console.log("Language changed to:", lang);
        const translations = await loadTranslations(lang);
        console.log("Loaded translations:", translations);
        i18n.addResourceBundle(lang, "translation", translations, true, true);
    });

    return i18n;
};

export const i18nInstance = initializeI18n();
export default i18n;