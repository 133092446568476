import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { MdMenu, MdClose, MdOutlineEventNote } from 'react-icons/md';
import { useTranslation } from "react-i18next";
import "../utils/i18n";
import axiosInstance from '../utils/axiosInstance';


const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [languages, setLanguages] = useState([]);
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);

  

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    const fetchLanguages = async () => {
      try {
        const response = await axiosInstance.get("/api/langapi/langs");
        if (response.data && response.data.langs) {
          setLanguages(response.data.langs);
        }
      } catch (error) {
        console.error("Error fetching languages:", error);
      }
    };

    fetchLanguages();
  }, []);

  const changeLanguage = async (lang) => {
    setLoading(true);
    try {
      await i18n.changeLanguage(lang);
    } catch (error) {
      console.error("Error changing language:", error);
    } finally {
      setLoading(false);
    }
  };





  return (
    <div className="relative">
      <div className="flex items-center h-[120px] mx-auto container justify-between">
        
  
      <Link to="/" className="flex items-center gap-8"> {/* Flexbox ve aralığı için gap-4 */}
      <img className="h-[80px] pl-1" src="/assets/logo.svg" alt="Kintecs Logo" />
      <img className="h-[100px]" src="/assets/telogo.png" alt="Kintecs Logo" />
    </Link>

       
        

    
        <div className="md:hidden">
          <button onClick={toggleMenu} className="text-black z-50 relative">
            {isMenuOpen ? <MdClose size={30} /> : <MdMenu size={30} />}
          </button>
        </div>

        <div className="hidden md:block">
        {/* <div className="flex gap-x-6 justify-end">
          <img className="h-9" src="/assets/brand-libart.svg" alt="Libart Logo" />
          <img className="h-9" src="/assets/brand-panora.svg" alt="Panora Logo" />
          <img className="h-9" src="/assets/solaglide-logo.svg" alt="Solaglide Logo" />
          
          <ul className="flex space-x-2">
            <li>EN</li>
            <li>|</li>
            <li>SR</li>
          </ul>
        </div> */}
          <ul className="flex font-bold text-base gap-x-4 items-center">
  <li><Link to="/products">{t("Products")}</Link></li>
  <li><Link to="/projects">{t("Projects")}</Link></li>
  <li><Link to="/videos">{t("Videos")}</Link></li>
  <li><Link to="/catalogues">{t("Catalogues")}</Link></li>
  <li><Link to="/about-us">{t("About Us")}</Link></li>
  <li><Link to="/contact">{t("Contact")}</Link></li>

  {loading && <p>Loading...</p>} {/* Yükleme durumu */}
    <ul className="relative flex gap-2">
    {languages?.length > 0 ? (
    languages.map((lang) => (
        <span
            key={lang.code}
            className={`cursor-pointer ${
                i18n.language === lang.code ? "font-bold underline" : "font-normal"
            }`}
            onClick={() => changeLanguage(lang.code)}
        >
            {lang.code.toUpperCase()}
        </span>
    ))
) : (
    <p>No Lang</p>
)}
    </ul>

            <li>
              <button className="bg-kintecs text-white px-4 py-2 rounded flex items-center gap-x-1 font-oswald font-bold text-sm">
                <MdOutlineEventNote />
                <Link to="/request-form">{t("Request Form")}</Link>
              </button>
            </li>
</ul>
        </div>
      </div>

      {isMenuOpen && (
        <div className="absolute top-0 left-0 w-full h-screen bg-white z-40 flex flex-col items-center justify-center space-y-6">
          <ul className="text-2xl font-bold text-center">
            <li className="mb-4"><Link to="/products" onClick={toggleMenu}>{t("Products")}</Link></li>
            <li className="mb-4"><Link to="/projects" onClick={toggleMenu}>{t("Projects")}</Link></li>
            <li className="mb-4"><Link to="/videos" onClick={toggleMenu}>{t("Videos")}</Link></li>
            <li className="mb-4"><Link to="/catalogues" onClick={toggleMenu}>{t("Catalogues")}</Link></li>
            <li className="mb-4"><Link to="/about-us" onClick={toggleMenu}>{t("About Us")}</Link></li>
            <li className="mb-4"><Link to="/contact" onClick={toggleMenu}>{t("Contact")}</Link></li>
            <li className="mt-4">
              <button className="bg-kintecs text-white px-5 py-1 rounded font-oswald font-bold text-xl">
                <Link to="/request-form" onClick={toggleMenu}>{t("Request Form")}</Link>
              </button>
            </li>
            <li><div className="flex gap-x-8 justify-center mt-12 items-center">
            <img className="w-[120px]  " src="/assets/logo.svg" alt="TE COOLING" />
            <img className="w-[60px]  " src="/assets/telogo.png" alt="TE COOLING" />
 
        </div></li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default Header;