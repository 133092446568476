import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from "react-i18next";
import "../utils/i18n";
import axios from 'axios';

const Footer = () => {

    const [languages, setLanguages] = useState([]);
    const { t, i18n } = useTranslation();
    const backendUrl = process.env.REACT_APP_BACKEND_URL;
  
    useEffect(() => {
      const fetchLanguages = async () => {
        try {
          const response = await axios.get(`${backendUrl}/api`); 
          setLanguages(response.data.languages); 
        } catch (error) {
          console.error("Error fetching languages:", error);
        }
      };
  
      fetchLanguages();
    }, []);
  
    const changeLanguage = (lang) => {
      i18n.changeLanguage(lang);
    };

  return (
    <div className="bg-kintecs">
        <div className="mx-auto container">

            <ul className="gap-x-20 text-white font-bold text-3xl pt-24 pb-16 hidden md:flex ">
                <li className=" hover:underline cursor-pointer"><Link to="/products">{t("Products")}</Link></li>
                <li className=" hover:underline cursor-pointer"><Link to="/projects">{t("Projects")}</Link></li>
                <li className=" hover:underline cursor-pointer"><Link to="/about-us">{t("About Us")}</Link></li>
                <li className=" hover:underline cursor-pointer"><Link to="/contact">{t("Contact")}</Link></li>
            </ul>

            <div className="border-b-[1px] border-gray-500 hidden md:block "></div>

            <div className="flex pt-8 pb-8 gap-x-14 p-4 md:p-0 md:mt-4 md:mb-4 items-center">
                <a>
                <img className="w-[120px]" src="/assets/logoW.png" alt="Libart" />
                </a>
                <a>
                <img className="w-[60px]  " src="/assets/telogoW.png" alt="TE COOLING" />
                </a>
            </div>

            <div className="border-b-[1px] border-gray-500 ml-4 mr-4 md:ml-0 md:mr-0"></div>

            <div className="md:flex justify-between pt-8 pb-24 ml-4 mr-4 md:ml-0 md:mr-0 md:items-center ">
                <p className="text-xs text-gray-500 text-center">{t("reserved")}</p>
                <ul className="gap-x-2 md:gap-x-6 flex justify-center mt-4 md:mt-0">
                    <li><a href="https://www.facebook.com/libartinternational" target="_blank"><img className=" bg-white rounded h-6 " src="/assets/social-facebook.svg" alt="Libart Facebook" /></a></li>
                    <li><a href="https://twitter.com/Libart" target="_blank"><img className=" bg-white rounded h-6 " src="/assets/social-twitter.svg" alt="Libart Twitter" /></a></li>
                    <li><a href="https://www.instagram.com/libartinternational/" target="_blank"><img className=" bg-white rounded h-6 " src="/assets/social-instagram.svg" alt="Libart Instagram" /></a></li>
                    <li><a href="https://www.linkedin.com/company/libartinternational/" target="_blank"><img className=" bg-white rounded h-6 " src="/assets/social-linkedin.svg" alt="Libart LinkedIn" /></a></li>
                    <li><a href="https://www.pinterest.com/libartcom/" target="_blank"><img className=" bg-white rounded h-6 " src="/assets/social-pinterest.svg" alt="Libart Pinterest" /></a></li>
                    <li><a href="https://www.youtube.com/@LibartInternational" target="_blank"><img className=" bg-white rounded h-6 " src="/assets/social-youtube.svg" alt="Libart YouTube" /></a></li>
                    <li><a href="https://vimeo.com/libartinternational" target="_blank"><img className=" bg-white rounded h-6 " src="/assets/social-vimeo.svg" alt="Libart Vimeo" /></a></li>
                </ul>
            </div>

        </div>
    </div>
  )
}

export default Footer