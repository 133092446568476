import React, { useState } from 'react'
import { Link, useNavigate} from 'react-router-dom';
import { IoIosArrowBack } from "react-icons/io";
import axiosInstance from '../utils/axiosInstance';

const Admin = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  const handleLogin = async (e) => {
    e.preventDefault();

    try{
      const response = await axiosInstance.post('/auth/login', {
        email: email,
        password: password,
      });

      if(response.data && response.data.accessToken){
        localStorage.setItem("token", response.data.accessToken)
        navigate("/admin/add-project")
      }

    } catch (error){
      if(error.response && error.response.data && error.response.data.message){
        setError(error.response.data.message);
      } else {
        setError("Server error. Please try again.");
      }
    }
  };

  return (
    <div>
        
        <div className=" mt-5 ml-5">
        <Link to="/" className="flex items-center space-x-2"><IoIosArrowBack /> <h1>Back</h1></Link>
        </div>
    <div className="bg-home-bg bg-center bg-cover h-screen flex justify-center items-center">
             <div className="flex justify-center items-center h-screen">
                 <div className="text-center">
                 <h1 className="text-2xl font-base mb-12">Control Panel<span className="text-primary-first text-3xl font-extrabold text-libart">.</span></h1>
                 
                 <form onSubmit={handleLogin}>
                 <label className="block relative mb-2 w-80">
                   <input value={email} onChange={(e) => setEmail(e.target.value)} type="text" name="email" className="bg-primary-third border border-primary-fourth w-full h-[38px] rounded-md focus:border-primary-second outline-none px-2 peer pt-3 opacity-50"
                  required/>
                   <small className="absolute top-[-1px] left-[9px] translate-y-1/2 text-s text-primary-sixth cursor-text pointer-events-none transition-all peer-valid:text-[10px] peer-valid:-translate-y-[-2px] peer-focus:text-[10px] peer-focus:-translate-y-[-2px]">E-mail</small>
                 </label>
     
                 <label className="block relative mb-2 w-80">
                   <input value={password} onChange={(e) => setPassword(e.target.value)} type="password" name="password" className="bg-primary-third border border-primary-fourth w-full h-[38px] rounded-md focus:border-primary-second outline-none px-2 peer pt-3 opacity-50" required/>
                   <small className="absolute top-[-1px] left-[9px] translate-y-1/2 text-s text-primary-sixth cursor-text pointer-events-none transition-all peer-valid:text-[10px] peer-valid:-translate-y-[-2px] peer-focus:text-[10px] peer-focus:-translate-y-[-2px]">Password</small>
                 </label>
                 {error && <div className="text-red-500 text-sm mt-2">{error}</div>}
  <button
    type="submit"
    className="bg-primary-first border border-primary-second rounded-md w-full h-[38px] text-primary-third font-semibold hover:bg-libart hover:text-white"
  >
    Login
  </button>
                     
                 </form>
                 </div>
             </div>
    
     </div>

    </div>
  )
}

export default Admin