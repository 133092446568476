import React, { useState } from 'react'
import { useTranslation } from "react-i18next";
import "../utils/i18n";
import axios from 'axios';
import Country from '../components/Country2';
import Loading from '../components/Loading';

const Request = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: ''
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const response = await axios.post('https://api.345.com.tr/api/forms/new', formData);
      
      if (response.data.success) {
        alert(t('form_success'));
        setFormData({
          name: '',
          email: '',
          phone: '',
          message: ''
        });
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      alert(t('form_error'));
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = (e) => {
    if (e && e.target) {
      setFormData(prev => ({
        ...prev,
        [e.target.name]: e.target.value
      }));
    }
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="mx-auto container mt-24 mb-24">
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          name="name"
          value={formData.name}
          onChange={handleChange}
          className="w-full border rounded-lg p-3 mb-4"
          placeholder={t("Name & Surname")}
          required
        />
        <input
          type="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          className="w-full border rounded-lg p-3 mb-4"
          placeholder={t("Email Address")}
          required
        />
        <Country 
          onChange={handleChange}
          value={formData.phone}
        />
        <textarea
          name="message"
          value={formData.message}
          onChange={handleChange}
          className="w-full border rounded-lg p-3 mb-4"
          placeholder={t("Your Message")}
          rows="4"
          required
        ></textarea>
        <button
          type="submit"
          className="w-full p-3 bg-kintecs text-white font-bold rounded-lg hover:bg-gray-500"
        >
          {t("Submit")}
        </button>
      </form>
    </div>
  );
};

export default Request;