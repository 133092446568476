import React, { useState, useEffect } from "react";
import { MdDeleteForever, MdDeleteSweep } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../utils/axiosInstance";

const ManageVideos = () => {
  const [videos, setVideos] = useState([]);
  const navigate = useNavigate();


  const getAllVideos = async () => {
    try {
      const response = await axiosInstance.get("/video/all");
      setVideos(response.data.videos);
    } catch (error) {
      console.error("Error fetching videos:", error);
    }
  };


  useEffect(() => {
    getAllVideos();
  }, []);

  return (
    <div className="p-8 bg-gray-100 min-h-screen ml-[218px]">
      <div className="mt-6 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        <div className="bg-white p-6 rounded-lg md:col-span-3 container mx-auto">
          <div className="flex justify-between items-start">
            <div>
              <h2 className="text-lg font-bold">Videos</h2>
              <h1>You can add or remove all videos from here.</h1>
            </div>
            <button
              onClick={() => navigate("/admin/add-video")}
              className="bg-libart text-white hover:bg-cyan-900 px-6 py-3 rounded-full font-semibold"
            >
              Add Video
            </button>
          </div>

          <div className="container mx-auto pt-6">
            <div className="overflow-x-auto">
              <table className="min-w-full bg-white border border-gray-200 rounded-lg">
                <thead className="bg-libart text-white">
                  <tr>
                    <th className="py-3 px-4 text-left">Title</th>
                    <th className="py-3 px-4 text-left">Project Number</th>
                    <th className="py-3 px-4 text-left">Video URL</th>
                    <th className="py-3 px-4 text-left">Thumbnail</th>
                    <th className="py-3 px-4 text-left">
                      <MdDeleteSweep size={26} />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {videos.map((video) => (
                    <tr
                      key={video._id}
                      className="border-b border-gray-200 hover:bg-gray-100 cursor-pointer"
                      onClick={() =>
                        navigate(`/admin/edit-video/${video._id}`)
                      }
                    >
                      <td className="py-3 px-4">{video.title || "No Title"}</td>
                      <td className="py-3 px-4">
                        {video.projectNumber || "No Number"}
                      </td>
                      <td className="py-3 px-4">
                        <a
                          href={video.videoUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-blue-500 hover:underline"
                        >
                          Watch
                        </a>
                      </td>
                      <td className="py-3 px-4">
                        {video.thumbnail ? (
                          <img
                          src={`https://api.345.com.tr${video.thumbnail}`}
                            alt="Thumbnail"
                            className="w-16 h-16 rounded"
                          />
                        ) : (
                          "No Thumbnail"
                        )}
                      </td>
                      <td className="py-3 px-4">
                        <button
                          className="text-red-500 hover:text-red-700"
                          
                        >
                          <MdDeleteForever size={26} />
                        </button>
                      </td>
                    </tr>
                  ))}
                  {videos.length === 0 && (
                    <tr>
                      <td colSpan="5" className="text-center py-4">
                        No videos found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageVideos;