import axios from "axios";

const axiosInstance = axios.create({
    baseURL: 'https://api.345.com.tr',
    withCredentials: true
});

// Request interceptor
axiosInstance.interceptors.request.use(
    (config) => {
        // FormData gönderirken Content-Type header'ını kaldır
        if (config.data instanceof FormData) {
            config.headers = {
                ...config.headers,
                'Content-Type': 'multipart/form-data'
            };
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default axiosInstance;