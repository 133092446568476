import React, { useEffect, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';
import { Autoplay } from 'swiper/modules'; 
import data from '../data/data.json';
import Products from '../components/Products';
import Subscribe from '../components/Subscribe';
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import "../utils/i18n";
import axiosInstance from '../utils/axiosInstance';
import { BASE_URL } from '../utils/constants';
import Loading from '../components/Loading';

const Home = () => {
  const [languages, setLanguages] = useState([]);
  const { t, i18n } = useTranslation();
  const [sliders, setSliders] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        console.log('Fetching data started...');
        
        // Önce sadece slider verilerini çekelim
        const slidersResponse = await axiosInstance.get("/api/slider/getall");
        console.log('Raw slider response:', slidersResponse);
        console.log('Slider data:', slidersResponse.data);

        if (slidersResponse.data) {
          setSliders(slidersResponse.data);
        }

        // Sonra languages verilerini çekelim
        const languagesResponse = await axiosInstance.get("/api");
        setLanguages(languagesResponse.data.languages);

      } catch (error) {
        console.error("Error fetching data:", error);
        if (error.response) {
          console.error("Response error:", error.response.data);
          console.error("Status code:", error.response.status);
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div>
      {console.log('Render - Current sliders state:', sliders)}
      <Swiper
        pagination={{
          dynamicBullets: true,
        }}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        speed={3000}
        modules={[Autoplay, Pagination]}
        className="mySwiper w-full"
      >
        {sliders && sliders.length > 0 ? (
          sliders.map((slider, index) => (
            <SwiperSlide key={index}>
              <div className="relative z-50">
                <img
                  className="w-full h-[30vh] lg:h-full md:h-full object-cover" 
                  src={`${BASE_URL}${slider.image}`}
                  alt={slider.title}
                  onError={(e) => {
                    console.error('Image load error for:', slider.image);
                    console.error('Full URL:', `${BASE_URL}${slider.image}`);
                  }}
                />
                <div className="absolute inset-0 bg-gradient-to-t from-black to-transparent opacity-80"></div>
                <div className="absolute bottom-0 left-0 w-full">
                  <div className="container mx-auto text-left">
                    <div className="bg-white bg-opacity-80 p-2 py-4 px-4 md:p-4 md:py-6 md:px-6 inline-block">
                      <h1 className="text-kintecs font-bold text-2xl md:text-3xl font-oswald mb-1 md:mb-5">
                        {slider.title}
                      </h1>
                      <h2 className="text-kintecs text-xl md:text-2xl font-oswald font-light">
                        {slider.subtitle}
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))
        ) : (
          <div>Loading sliders...</div>
        )}
      </Swiper>

      {/* YouTube Video */}
      <div className="mx-auto container mt-24 mb-24 gap-x-10 p-4 md:p-0">
  <div className="grid grid-cols-1 lg:grid-cols-2 gap-10 items-center">
    

    <div>
      <h1 className="font-oswald font-black text-kintecs text-2xl mb-5 text-center lg:text-left">
      {t("join")}
      </h1>
      <p className="text-base text-kintecs font-regular mb-5">
      {t("about2")}
      </p>
      <p className="text-base text-kintecs font-regular mb-5">
      {t("about3")}
      </p>
      <h2 className="text-base text-kintecs font-bold">
      {t("about4")}
      </h2>
    </div>


    <div className="w-full h-full mt-8 lg:mt-0">
      <iframe className="w-full aspect-video" src="https://www.youtube.com/embed/PZJBkx8Mgos?si=vDpoiPwsvZFjLKD0" data-mce-fragment="1"></iframe>
    </div>
  
  </div>
</div>

    {/* Çözümler Kısmı */}
    <Products />

    {/* Projeler */}
    <div className="mx-auto container mt-24 p-4 md:p-0">
  <div className="flex justify-between">
    <h1 className="font-oswald font-bold text-kintecs text-4xl">{t("Projects")}</h1>
    <Link to="/projects">
      <button className="text-white bg-kintecs rounded-lg py-3 px-4 text-base font-oswald font-bold">
      {t("See All Projects")}
      </button>
    </Link>
  </div>
  
  <div className="w-full border-b border-kintecs mt-3"></div>

  <div className="mt-10">
    <Swiper
      breakpoints={{
        140: { slidesPerView: 1, spaceBetween: -40 }, 
        768: { slidesPerView: 2, spaceBetween: 20 }, 
        1024: { slidesPerView: 3, spaceBetween: 30 },
        1280: { slidesPerView: 4, spaceBetween: 30 }  
      }}
      autoplay={{
        delay: 2500,
        disableOnInteraction: false,
      }}
      speed={5000}
      modules={[Autoplay, Pagination]}
      className="mySwiper"
    >
      {data.projects.slice(0, 6).map((projects, index) => (
        <SwiperSlide key={index}>
            <Link to={`/projects/${projects.slug}`}>
          <div className="relative group">
            <img
              className="w-[300px] h-[200px] object-cover mt-3 rounded transform transition-transform duration-500 group-hover:scale-110"
              src={projects.pictures[0]}
              alt={projects.projectName}
            />
          </div>
          <h2 className="font-oswald text-xl font-bold mt-3">{projects.projectName}</h2>
          <h3>{projects.location}</h3>
          </Link>
        </SwiperSlide>
      ))}
    </Swiper>
  </div>
</div>

    {/* Mail Bülten */}
    <Subscribe />

    


    </div>
  )
}

export default Home