import React, { useState } from "react";
import axiosInstance from "../utils/axiosInstance";
import { useNavigate } from "react-router-dom";

const AddVideo = () => {
  const [formData, setFormData] = useState({
    title: "",
    projectNumber: "",
    videoUrl: "",
    location: "",
  });
  const [thumbnail, setThumbnail] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  // Form değişikliklerini yakalama
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Thumbnail seçimi
  const handleThumbnailChange = (e) => {
    setThumbnail(e.target.files[0]);
  };

  // Form gönderimi
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.title || !formData.videoUrl || !formData.location || !thumbnail) {
      alert("Please fill in all fields and upload a thumbnail.");
      return;
    }

    setIsLoading(true);

    const form = new FormData();
    form.append("title", formData.title);
    form.append("projectNumber", formData.projectNumber);
    form.append("videoUrl", formData.videoUrl);
    form.append("location", formData.location);
    form.append("thumbnail", thumbnail);

    try {
      await axiosInstance.post("/video/upload", form, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      alert("Video added successfully!");
      navigate("/admin/videos");
    } catch (error) {
      console.error("Error adding video:", error);
      alert("Failed to add video. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="p-8 bg-gray-100 min-h-screen ml-[218px]">
      <div className="bg-white p-6 rounded-lg max-w-2xl mx-auto shadow-md">
        <h1 className="text-2xl font-bold mb-6">Add Video</h1>
        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <label htmlFor="title" className="block text-gray-700 font-semibold">
              Video Title
            </label>
            <input
              type="text"
              id="title"
              name="title"
              value={formData.title}
              onChange={handleChange}
              className="w-full px-4 py-2 mt-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-cyan-600"
              placeholder="Enter video title"
              required
            />
          </div>
          <div>
            <label
              htmlFor="projectNumber"
              className="block text-gray-700 font-semibold"
            >
              Project Number
            </label>
            <input
              type="text"
              id="projectNumber"
              name="projectNumber"
              value={formData.projectNumber}
              onChange={handleChange}
              className="w-full px-4 py-2 mt-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-cyan-600"
              placeholder="Enter project number"
            />
          </div>
          <div>
            <label
              htmlFor="videoUrl"
              className="block text-gray-700 font-semibold"
            >
              Video URL
            </label>
            <input
              type="text"
              id="videoUrl"
              name="videoUrl"
              value={formData.videoUrl}
              onChange={handleChange}
              className="w-full px-4 py-2 mt-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-cyan-600"
              placeholder="Enter video URL (e.g., YouTube embed link)"
              required
            />
          </div>
          <div>
            <label
              htmlFor="location"
              className="block text-gray-700 font-semibold"
            >
              Location
            </label>
            <input
              type="text"
              id="location"
              name="location"
              value={formData.location}
              onChange={handleChange}
              className="w-full px-4 py-2 mt-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-cyan-600"
              placeholder="Enter location (e.g., Belgrade / SRB)"
              required
            />
          </div>
          <div>
            <label
              htmlFor="thumbnail"
              className="block text-gray-700 font-semibold"
            >
              Thumbnail
            </label>
            <input
              type="file"
              id="thumbnail"
              accept="image/*"
              onChange={handleThumbnailChange}
              className="w-full px-4 py-2 mt-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-cyan-600"
              required
            />
          </div>
          <div>
            <button
              type="submit"
              disabled={isLoading}
              className={`w-full px-4 py-2 text-white font-semibold rounded-lg ${
                isLoading ? "bg-gray-400" : "bg-cyan-600 hover:bg-cyan-700"
              } focus:outline-none`}
            >
              {isLoading ? "Adding..." : "Add Video"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddVideo;