import React from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useTranslation } from "react-i18next";

const Country = ({ onChange, value }) => {
  const { t } = useTranslation();

  const handlePhoneChange = (value) => {
    // Phone değişikliğini simüle eden bir event objesi oluştur
    const simulatedEvent = {
      target: {
        name: 'phone',
        value: value
      }
    };
    onChange(simulatedEvent);
  };

  return (
    <div className="mb-4">
      <PhoneInput
        country={'tr'}
        value={value}
        onChange={handlePhoneChange}
        inputClass="w-full !h-12 !text-base"
        containerClass="w-full"
        placeholder={t("Phone Number")}
      />
    </div>
  );
};

export default Country;