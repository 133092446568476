import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import axiosInstance from '../../utils/axiosInstance';
import { AdminHeader } from '../../components/AdminHeader';
import { BASE_URL } from '../../utils/constants';

const SliderManagement = () => {
  const { t } = useTranslation();
  const [sliders, setSliders] = useState([]);
  const [newSlider, setNewSlider] = useState({
    title: '',
    subtitle: '',
    order: 0
  });
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);

  useEffect(() => {
    fetchSliders();
  }, []);

  const fetchSliders = async () => {
    try {
      const response = await axiosInstance.get("/api/slider/getall");
      setSliders(response.data);
    } catch (error) {
      console.error("Error fetching sliders:", error);
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedFile(file);
      // Create preview URL
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewUrl(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append('image', selectedFile);
      formData.append('title', newSlider.title);
      formData.append('subtitle', newSlider.subtitle);
      formData.append('order', newSlider.order);

      const token = localStorage.getItem('token');
      console.log('Token:', token);

      const response = await axiosInstance.post("/api/slider/new", formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${token}`
        }
      });

      if (response.data) {
        console.log("Slider added successfully:", response.data);
        fetchSliders();
        setNewSlider({ title: '', subtitle: '', order: 0 });
        setSelectedFile(null);
        setPreviewUrl(null);
      }
    } catch (error) {
      console.error("Error details:", error.response || error);
      if (error.response?.status === 401) {
        alert("Unauthorized! Please login again.");
        // Opsiyonel: Kullanıcıyı login sayfasına yönlendir
        // window.location.href = '/admin';
      } else {
        alert("Error adding slider. Please check console for details.");
      }
    }
  };

  const handleDelete = async (id) => {
    try {
      await axiosInstance.delete(`/api/slider/delete/${id}`);
      fetchSliders();
    } catch (error) {
      console.error("Error deleting slider:", error);
    }
  };

  return (
    <div className="flex">
      <AdminHeader />
      <div className="flex-1 ml-[218px] p-8">
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-2xl font-bold text-gray-800">Slider Management</h1>
          <button 
            onClick={() => document.getElementById('addSliderForm').scrollIntoView({ behavior: 'smooth' })}
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition-colors"
          >
            Add New Slider
          </button>
        </div>

        {/* Existing Sliders */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-8">
          {sliders.map((slider) => (
            <div key={slider._id} className="bg-white rounded-lg shadow-md overflow-hidden">
              <img 
                src={`${BASE_URL}${slider.image}`}
                alt={slider.title} 
                className="w-full h-48 object-cover"
              />
              <div className="p-4">
                <h3 className="font-bold text-lg mb-2">{slider.title}</h3>
                <p className="text-gray-600 mb-2">{slider.subtitle}</p>
                <p className="text-sm text-gray-500 mb-4">Order: {slider.order}</p>
                <button
                  onClick={() => handleDelete(slider._id)}
                  className="w-full bg-red-500 text-white py-2 rounded hover:bg-red-600 transition-colors"
                >
                  Delete
                </button>
              </div>
            </div>
          ))}
        </div>

        {/* Add New Slider Form */}
        <div id="addSliderForm" className="bg-white rounded-lg shadow-md p-6">
          <h2 className="text-xl font-bold mb-4">Add New Slider</h2>
          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <label className="block text-gray-700 mb-2">Image</label>
              <input
                type="file"
                accept="image/*"
                onChange={handleFileChange}
                className="w-full border rounded p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                required
              />
              {previewUrl && (
                <img 
                  src={previewUrl} 
                  alt="Preview" 
                  className="mt-2 w-full h-48 object-cover rounded"
                />
              )}
            </div>
            <div>
              <label className="block text-gray-700 mb-2">Title</label>
              <input
                type="text"
                placeholder="Enter title"
                value={newSlider.title}
                onChange={(e) => setNewSlider({...newSlider, title: e.target.value})}
                className="w-full border rounded p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                required
              />
            </div>
            <div>
              <label className="block text-gray-700 mb-2">Subtitle</label>
              <input
                type="text"
                placeholder="Enter subtitle"
                value={newSlider.subtitle}
                onChange={(e) => setNewSlider({...newSlider, subtitle: e.target.value})}
                className="w-full border rounded p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                required
              />
            </div>
            <div>
              <label className="block text-gray-700 mb-2">Order</label>
              <input
                type="number"
                placeholder="Enter order number"
                value={newSlider.order}
                onChange={(e) => setNewSlider({...newSlider, order: parseInt(e.target.value)})}
                className="w-full border rounded p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                required
              />
            </div>
            <button 
              type="submit" 
              className="w-full bg-blue-500 text-white py-2 rounded hover:bg-blue-600 transition-colors"
            >
              Add Slider
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SliderManagement; 