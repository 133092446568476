import React, { useEffect, useState } from 'react'
import { IoIosArrowBack} from "react-icons/io";
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../utils/axiosInstance';
import { useParams } from "react-router-dom";

const EditProject = () => {
    const { id } = useParams();
    const [isUploading, setIsUploading] = useState(false);
    const [isLoading, setIsLoading] = useState(true); 
    const navigate = useNavigate();
    const [languages, setLanguages] = useState([]);
    const [errors, setErrors] = useState({});
    const [error, setError] = useState('');
    const backendUrl = process.env.REACT_APP_BACKEND_URL;
    const [formData, setFormData] = useState({
      projectName: {}, 
      title: {},      
      desc: {},       
      location: "",
      product: "",
      units: "",
      size: "",
      glazing: "",
      projectNumber: "",
      banner: [],
      pics: [],
    });

    
 
    const validateForm = () => {
      let newErrors = {};
  
      // Temel alanların kontrolü
      if (!formData.location) newErrors.location = "Location is required.";
      if (!formData.product) newErrors.product = "Product is required.";
      if (!formData.units) newErrors.units = "Units is required.";
      if (!formData.size) newErrors.size = "Size is required.";
      if (!formData.glazing) newErrors.glazing = "Glazing is required.";
      if (!formData.projectNumber) newErrors.projectNumber = "Project Number is required.";
  
      // Dil bazlı alanların kontrolü
      languages.forEach(lang => {
        const langCode = lang.code;
        
        // İngilizce alanlar her zaman zorunlu
        if (langCode === 'en' || lang.isRequired) {
          if (!formData.title?.[langCode]) {
            newErrors.title = newErrors.title || {};
            newErrors.title[langCode] = `Title in ${lang.name} is required.`;
          }
          
          if (!formData.desc?.[langCode]) {
            newErrors.desc = newErrors.desc || {};
            newErrors.desc[langCode] = `Description in ${lang.name} is required.`;
          }
          
          if (!formData.projectName?.[langCode]) {
            newErrors.projectName = newErrors.projectName || {};
            newErrors.projectName[langCode] = `Project Name in ${lang.name} is required.`;
          }
        }
      });
  
      // Dosya kontrolü
      if (!formData.banner || formData.banner.length === 0) {
        newErrors.banner = "At least one banner image is required.";
      }
      if (!formData.pics || formData.pics.length === 0) {
        newErrors.pics = "At least one project image is required.";
      }
  
      setErrors(newErrors);
      return Object.keys(newErrors).length === 0;
    };

    useEffect(() => {
      const fetchLanguages = async () => {
        try {
          // İngilizce her zaman olacak
          const defaultLanguages = [
            { code: 'en', name: 'English', isRequired: true },
            { code: 'sr', name: 'Serbia' }
          ];
          
          setLanguages(defaultLanguages);

          const initialFormData = {
            title: {},
            desc: {},
            projectName: {},
            location: "",
            product: "",
            units: "",
            size: "",
            glazing: "",
            projectNumber: "",
            banner: [],
            pics: []
          };
  
          defaultLanguages.forEach((lang) => {
            initialFormData.title[lang.code] = ""; 
            initialFormData.desc[lang.code] = "";
            initialFormData.projectName[lang.code] = "";
          });
  
          setFormData(initialFormData);
        } catch (error) {
          console.error("Error initializing languages:", error);
          setError("Diller yüklenirken bir hata oluştu");
        }
      };
  
      fetchLanguages();
    }, []);

    useEffect(() => {
      const fetchProjectDetails = async () => {
        if (!id) {
          setIsLoading(false);
          return;
        }

        setIsLoading(true); 
        try {
          const response = await axiosInstance.get(`/api/projects/${id}`);
          const project = response.data.project;

          if (project) {
            setFormData({
              projectName: project.projectName || {},
              title: project.title || {},
              desc: project.descb || {},
              location: project.location || "",
              product: project.product || "",
              units: project.units || "",
              size: project.size || "",
              glazing: project.glazing || "",
              projectNumber: project.projectNumber || "",
              banner: project.banner || [],
              pics: project.pics || []
            });
          }
        } catch (error) {
          console.error("Error fetching project details:", error);
          setError("Proje detayları yüklenirken bir hata oluştu");
        } finally {
          setIsLoading(false); 
        }
      };
  
      fetchProjectDetails();
    }, [id]);
  
  if (isLoading) {
      return <div>Loading project details...</div>;
  }
  
  
  
    if (!formData) {
      return <div>Loading...</div>;
    }
  
  
    const handleInputChange = (e, field, lang) => {
      const { value } = e.target;
    
      setFormData((prevData) => ({
        ...prevData,
        [field]: {
          ...prevData[field],
          [lang]: value,
        },
      }));
    };
  
   
    const handleOtherInputChange = (e) => {
      const { name, value } = e.target;
  
      
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    };
  

    const handleSubmit = async (e) => {
      e.preventDefault();
      
      if (!validateForm() || isUploading) return;
      
      setIsUploading(true);
      setError('');
    
      try {
        const formDataToSend = new FormData();

        // Dil bazlı alanları hazırla
        formDataToSend.append('projectName', JSON.stringify(formData.projectName));
        formDataToSend.append('title', JSON.stringify(formData.title));
        formDataToSend.append('desc', JSON.stringify(formData.desc));

        // Diğer alanları ekle
        formDataToSend.append('location', formData.location);
        formDataToSend.append('product', formData.product);
        formDataToSend.append('units', formData.units);
        formDataToSend.append('size', formData.size);
        formDataToSend.append('glazing', formData.glazing);
        formDataToSend.append('projectNumber', formData.projectNumber);

        // Dosyaları ekle
        if (formData.banner && formData.banner.length > 0) {
          formData.banner.forEach(file => {
            formDataToSend.append('banner', file);
          });
        }

        if (formData.pics && formData.pics.length > 0) {
          formData.pics.forEach(file => {
            formDataToSend.append('pics', file);
          });
        }

        const response = await axiosInstance.post("/api/upload-project", formDataToSend);

        console.log("Project saved successfully:", response.data);
        navigate("/admin/add-project");
      } catch (error) {
        console.error("Error saving project:", error);
        setError("Proje kaydedilirken bir hata oluştu. Lütfen tekrar deneyin.");
      } finally {
        setIsUploading(false);
      }
    };

  const handleFileChange = (e, field) => {
    const files = Array.from(e.target.files);
    console.log(`${field} files:`, files);
    
    setFormData(prevData => ({
      ...prevData,
      [field]: files
    }));
  };

  const handleBannerChange = (e) => {
    handleFileChange(e, 'banner');
  };

  const handlePicsChange = (e) => {
    handleFileChange(e, 'pics');
  };

    const handleNavigation = () => {
      navigate('/admin/add-project'); 
    };

  return (
    <div> <div className="p-8 bg-gray-100 min-h-screen ml-[218px]">
    <div className="mt-6 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
      <div className="bg-white p-6 rounded-lg md:col-span-3 container mx-auto">
      <div className="w-full bg-white p-6 rounded-lg ">
      <div className="text-sm text-gray-700 font-normal flex items-center space-x-1  justify-start hover:text-gray-400 cursor-pointer" onClick={handleNavigation}>
        <IoIosArrowBack size={18}/> 
        <h1>All Projects</h1>
      </div>
      
  <h1 className="text-3xl font-bold mb-6 text-center">Edit Project</h1>
  {error && <div className="text-red-500 text-center mb-4">{error}</div>}
  <form className="space-y-6" onSubmit={handleSubmit}>
   
  {["projectName", "title", "desc"].map((field) => (
    <div key={field} className="mb-6">
      <label className="block text-xl font-medium text-gray-700 mb-4">
        {field === "projectName"
          ? "Project Name"
          : field === "title"
          ? "Title"
          : "Description"}
      </label>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        {languages.map((lang) => (
          <div key={`${field}-${lang.code}`} className="mb-4">
            <label className="block text-sm text-gray-600 mb-1">
              {lang.name} ({lang.code.toUpperCase()})
              {(lang.code === 'en' || lang.isRequired) && (
                <span className="text-red-500 ml-1">*</span>
              )}
            </label>
            {field === "desc" ? (
              <textarea
                id={`${field}-${lang.code}`}
                name={`${field}-${lang.code}`}
                placeholder={`Enter ${field} in ${lang.name}`}
                className={`block w-full rounded-md border-gray-300 shadow-sm focus:border-libart focus:ring-libart ${
                  errors[field]?.[lang.code] ? 'border-red-500' : ''
                }`}
                value={formData[field]?.[lang.code] || ""}
                onChange={(e) => handleInputChange(e, field, lang.code)}
                rows={4}
                required={lang.code === 'en' || lang.isRequired}
              />
            ) : (
              <input
                type="text"
                id={`${field}-${lang.code}`}
                name={`${field}-${lang.code}`}
                placeholder={`Enter ${field} in ${lang.name}`}
                className={`block w-full rounded-md border-gray-300 shadow-sm focus:border-libart focus:ring-libart ${
                  errors[field]?.[lang.code] ? 'border-red-500' : ''
                }`}
                value={formData[field]?.[lang.code] || ""}
                onChange={(e) => handleInputChange(e, field, lang.code)}
                required={lang.code === 'en' || lang.isRequired}
              />
            )}
            {errors[field]?.[lang.code] && (
              <p className="text-red-500 text-sm mt-1">{errors[field][lang.code]}</p>
            )}
          </div>
        ))}
      </div>
    </div>
  ))}

  
{["location", "product", "units", "size", "glazing", "projectNumber"].map((field) => (
  <div key={field} className="mb-6">
    <label className="block text-lg font-medium text-gray-700 mb-2">
      {field.charAt(0).toUpperCase() + field.slice(1)}
    </label>
    <input
      type="text"
      id={field} 
      name={field}
      className={`block w-full rounded-md border-gray-300 shadow-sm focus:border-libart focus:ring-libart ${
        errors[field] ? 'border-red-500' : ''
      }`}
      value={formData[field] || ""} 
      onChange={handleOtherInputChange} 
    />
    {errors[field] && (
      <p className="text-red-500 text-sm mt-1">{errors[field]}</p>
    )}
  </div>
))}


<div>
  <div className="mb-6">
    <h2 className="text-lg font-medium text-gray-700 mb-2">Banner Images</h2>
    <div className="flex space-x-4">
      {formData.banner?.length > 0 ? (
        formData.banner.map((image, index) => (
          <img
            key={index}
            src={`${backendUrl}${image}`}
            alt={`Banner ${index + 1}`}
            className="w-32 h-32 object-cover rounded-lg shadow-md"
          />
        ))
      ) : (
        <p className="text-gray-500">No banner images uploaded</p>
      )}
    </div>
  </div>

  <div className="mb-6">
    <h2 className="text-lg font-medium text-gray-700 mb-2">Project Pics</h2>
    <div className="flex space-x-4">
      {formData.pics?.length > 0 ? (
        formData.pics.map((image, index) => (
          <img
            key={index}
            src={`${backendUrl}${image}`} 
            alt={`Pics ${index + 1}`}
            className="w-32 h-32 object-cover rounded-lg shadow-md"
          />
        ))
      ) : (
        <p className="text-gray-500">No project pics uploaded</p>
      )}
    </div>
  </div>
</div>
    
  {/* Banner Upload */}
  <div className="mb-6">
    <label className="block text-lg font-medium text-gray-700 mb-2">
      Banner Image
    </label>
    <input
      type="file"
      accept="image/*"
      onChange={handleBannerChange}
      className={`block w-full text-sm text-gray-500
        file:mr-4 file:py-2 file:px-4
        file:rounded-full file:border-0
        file:text-sm file:font-semibold
        file:bg-libart file:text-white
        hover:file:bg-cyan-900 ${errors.banner ? 'border-red-500' : ''}`}
    />
    {errors.banner && (
      <p className="text-red-500 text-sm mt-1">{errors.banner}</p>
    )}
    {formData.banner && formData.banner.length > 0 && (
      <div className="mt-2">
        {formData.banner.map((file, index) => (
          <div key={index} className="text-sm text-gray-600">
            {file.name}
          </div>
        ))}
      </div>
    )}
  </div>

  {/* Project Images Upload */}
  <div className="mb-6">
    <label className="block text-lg font-medium text-gray-700 mb-2">
      Project Images
    </label>
    <input
      type="file"
      accept="image/*"
      multiple
      onChange={handlePicsChange}
      className={`block w-full text-sm text-gray-500
        file:mr-4 file:py-2 file:px-4
        file:rounded-full file:border-0
        file:text-sm file:font-semibold
        file:bg-libart file:text-white
        hover:file:bg-cyan-900 ${errors.pics ? 'border-red-500' : ''}`}
    />
    {errors.pics && (
      <p className="text-red-500 text-sm mt-1">{errors.pics}</p>
    )}
    {formData.pics && formData.pics.length > 0 && (
      <div className="mt-2">
        {formData.pics.map((file, index) => (
          <div key={index} className="text-sm text-gray-600">
            {file.name}
          </div>
        ))}
      </div>
    )}
  </div>

  <div className="flex justify-end mt-6">
    <button
      type="submit"
      disabled={isUploading}
      className={`px-6 py-2 bg-libart text-white rounded-md hover:bg-cyan-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-libart ${
        isUploading ? 'opacity-50 cursor-not-allowed' : ''
      }`}
    >
      {isUploading ? 'Saving...' : 'Save Project'}
    </button>
  </div>

  </form>
</div>
      </div>
    </div>

    
  </div></div>
  )
}

export default EditProject