import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from "react-i18next";
import "../utils/i18n";
import axios from 'axios';

const Products = () => {

  const [languages, setLanguages] = useState([]);
  const { t, i18n } = useTranslation();
  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  useEffect(() => {
    const fetchLanguages = async () => {
      try {
        const response = await axios.get(`${backendUrl}/api`); 
        setLanguages(response.data.languages); 
      } catch (error) {
        console.error("Error fetching languages:", error);
      }
    };

    fetchLanguages();
  }, []);

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
  };

  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-y-12 lg:gap-x-20 container mx-auto py-12 pl-4 pr-4 md:pr-0 md:pl-0">


    <div className="">
      <img className="rounded-t-xl w-full" src="/assets/istanbul.webp" alt="İstanbul" />
      <h1 className="font-oswald text-4xl text-kintecs font-bold mt-4">{t("fixedku")}</h1>
      <div className="flex mt-3 gap-x-4">
        <div className="w-7/12">
          <p className="text-base text-gray-700">{t("fixedkuw")}</p>
          <Link to="/products/ku-fc"><button className="text-white bg-kintecs px-6 py-3 font-oswald font-bold text-lg mt-4 rounded hover:bg-gray-500">{t("show")}</button></Link>
        </div>
        <div className="w-5/12">
          <img className="w-full h-auto rounded" src="/assets/kufc.webp" alt="KU-FC" />
        </div>
      </div>
    </div>
  
  
    <div className="">
      <img className="rounded-t-xl w-full" src="/assets/istanbul2.webp" alt="İstanbul" />
      <h1 className="font-oswald text-4xl text-kintecs font-bold mt-4">{t("portku")}</h1>
      <div className="flex mt-3 gap-x-4">
        <div className="w-7/12">
          <p className="text-base text-gray-700">{t("portkuw")}</p>
          <Link to="/products/ku-fb"><button className="text-white bg-kintecs px-6 py-3 font-oswald font-bold text-lg mt-4 rounded hover:bg-gray-500">{t("show")}</button></Link>
        </div>
        <div className="w-5/12">
          <img className="w-full h-auto rounded" src="/assets/kufb.webp" alt="KU-FC" />
        </div>
      </div>
    </div>
  
      </div>
  )
}

export default Products