import React, { useEffect, useState } from 'react'
import axiosInstance from '../utils/axiosInstance';
import { Link } from 'react-router-dom'


const ProjectsComponent = () => {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const backendUrl = "https://api.345.com.tr";
  

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        console.log('Fetching projects...');
        const response = await axiosInstance.get("/api/projects");
        console.log('Projects response:', response.data);
        if (response.data && response.data.projects) {
          setProjects(response.data.projects);
        }
      } catch (error) {
        console.error('Error fetching projects:', error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchProjects();
  }, []);

  if (loading) {
    return <div className="flex justify-center items-center min-h-[200px]">Loading projects...</div>;
  }

  if (error) {
    return <div className="flex justify-center items-center min-h-[200px] text-red-500">Error: {error}</div>;
  }

  if (!projects.length) {
    return <div className="flex justify-center items-center min-h-[200px]">No projects found.</div>;
  }

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-x-5 lg:gap-x-10 mt-12 gap-y-5 pr-4 pl-4 md:pr-0 md:pl-0">
      {projects.map((project, index) => (
        <div key={project._id || index} className="group">
          <Link to={`/projects/${project.slug}`}>
            <div className="relative">
              <img
                className="w-full h-[300px] object-cover mt-3 rounded transform transition-transform duration-500 group-hover:scale-105"
                src={project.banner && project.banner[0] ? `${backendUrl}${project.banner[0]}` : '/assets/default-project.jpg'}
                alt={project.projectName?.en || project.projectName || 'Project'}
                onError={(e) => {
                  console.error('Image load error:', e);
                  e.target.src = '/assets/default-project.jpg';
                }}
              />
            </div>
            <h2 className="font-oswald text-xl font-bold mt-3">
              {project.projectName?.en || project.projectName || 'Unknown Project'}
            </h2>
            <h3>{project.location || 'Unknown Location'}</h3>
          </Link>
        </div>
      ))}
    </div>
  );
};

export default ProjectsComponent;